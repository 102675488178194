import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="Seite nicht gefunden" />
      <div className="h-screen flex items-center justify-center bg-brand-white">
        <h2 className="text-2xl font-light inline-block my-8 p-3">
          404: Seite nicht gefunden
        </h2>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
